import React from "react";

const ContentLinks = () => {

    return (

        <>
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20 bg-coolGray-100 text-coolGray-800 my-16 font-sans-serif text-theme-primary-text">
	<h2 className="mb-8 text-4xl font-bold leading-none text-center">Top Picks - Recommended Websites, Apps & Podcasts</h2>
	<ul className="grid gap-3 md:grid-cols-2 lg:grid-cols-3">
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://www.deepakchopra.com/" target="_blank" rel="noreferrer" className="pl-3"><span><b>Deepak Chopra</b><br />Author and alternative medicine advocate</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://brenebrown.com/" target="_blank" rel="noreferrer" className="pl-3"><span><b>Brene Brown</b><br />Professor, lecturer, author, and podcast host</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://eckharttolle.com/" target="_blank" rel="noreferrer" className="pl-3"><span><b>Eckhart Tolle</b><br />Spiritual teacher and self-help author</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://www.5lovelanguages.com/quizzes" target="_blank" rel="noreferrer" className="pl-3"><span><b>Quizzes</b><br />Quizzes to strengthen your relationships</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://www.webuybooks.co.uk/" target="_blank" rel="noreferrer" className="pl-3"><span><b>We Buy Books</b><br />Sell your books for cash!</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://thisgirlcanorganise.com/" target="_blank" rel="noreferrer" className="pl-3"><span><b>This Girl Can Organise</b><br />A bespoke and personal streamlining service.</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://www.youtube.com/watch?v=ZW_rjA0b_Zk" target="_blank" rel="noreferrer" className="pl-3"><span><b>Sleep Therapy</b><br />3 Hours of Intense Relaxation.</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://www.youtube.com/watch?v=nDQYdU5p0KY" target="_blank" rel="noreferrer" className="pl-3"><span><b>Awareness test</b><br />Transport for London</span></a>
		</li>
		<li className="flex items-center">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="40" className="fill-current text-theme-accent">
				<path d="M426.072,86.928A238.75,238.75,0,0,0,88.428,424.572,238.75,238.75,0,0,0,426.072,86.928ZM257.25,462.5c-114,0-206.75-92.748-206.75-206.75S143.248,49,257.25,49,464,141.748,464,255.75,371.252,462.5,257.25,462.5Z"></path>
				<polygon points="221.27 305.808 147.857 232.396 125.23 255.023 221.27 351.063 388.77 183.564 366.142 160.937 221.27 305.808"></polygon>
			</svg>
			<a href="https://konmari.com/" target="_blank" rel="noreferrer" className="pl-3"><span><b>Marie Kondo</b><br />Tidying Expert & Bestselling Author</span></a>
		</li>
	</ul>
</div>
        </>
    )
}

export default ContentLinks