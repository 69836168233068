import * as React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Distributors from "../components/Distributors";
import Contact from "../components/Contact";
import CtaSmall from "../components/CtaSmall";
import CtaLarge from "../components/CtaLarge";
import Profile from "../components/Profile";
import Downloads from "../components/Downloads";
import BookAdds from "../components/BookAdds";
import Quotes from "../components/Quotes";
import Gallery from "../components/Gallery";
import ContentLinks from "../components/ContentLinks";

const IndexPage = ({ location }) => {
  return (
    <>
      <Helmet defer={false} htmlAttributes={{lang: 'en',}}>
        <title>AJ Satori - Author - Think Positive, Be Happy, Start Living</title>
        <meta name="description" content="A J Satori is passionate about others developing a positive mindset and to become AWARE" />
      </Helmet>
      <div id="top" className="invisible"></div>
      <Layout location={location}>
        <Hero location={location} />
        <div id="purchase" className="invisible"></div>
        <Distributors location={location} />
        <CtaLarge location={location} />
        <Gallery />
        <ContentLinks />
        <div id="author" className="invisible"></div>
        <Profile />
        <div id="downloads" className="invisible"></div>
        <Downloads />
        <BookAdds location={location} />
        <Quotes />
        <CtaSmall location={location} />
        <div id="contact" className="invisible"></div>
        <Contact />
      </Layout>
    </>
  )
}

export default IndexPage
